import React, { useState }  from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import axios from "axios";

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';

import hero_img from "../images/hero.svg"

import { motion } from "framer-motion"

const budget = [
  { id: 'b-lt-1500', title: 'Less than £1,500' },
  { id: 'b-1500-3000', title: '£1,500 to £3,000' },
  { id: 'b-3000-7000', title: '£3,000 to £7,000' },
  { id: 'b-mt-7000', title: 'More than £7,000' },
]



const IndexPage = () => {
    
    const [serverState, setServerState] = useState({
      submitting: false,
      status: null
    });
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const handleServerResponse = (ok, msg, form) => {
      setServerState({
        submitting: false,
        status: { ok, msg }
      });
      if (ok) {
        form.reset();
        setShowSuccess(true);
        window.location.href = "#message";
      } else {
        setShowError(true);
        window.location.href = "#message";
      }
    };
    const handleOnSubmit = e => {
      e.preventDefault();
      const form = e.target;
      setServerState({ submitting: true });
      setShowSuccess(false);
      setShowError(false);
      axios({
        method: "post",
        url: "https://getform.io/f/4a1b644a-0554-47f1-8586-cc8a3f1d206b",
        data: new FormData(form)
      })
        .then(r => {
          handleServerResponse(true, "Thanks!", form);
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form);
        });
    };
    return (
  <Layout>
    <SEO title="Contact Me - UK Based Freelance Web Designer/Developer" />
    <div className="container mx-auto px-4 max-w-7xl relative z-20">
      <div className="lg:grid grid-cols-12 gap-16 items-center">
        <div className="col-span-12 text-center">
          <motion.h1 initial={{ opacity: 0, y: 15 }} whileInView={{ opacity: 1, y: 0 }} viewport={{ once: true }} className="mx-auto text-4xl md:text-5xl lg:text-6xl font-medium leading-tight md:leading-tight lg:leading-tight tracking-tight">Let's build <span className="bg-clip-text text-transparent bg-gradient-to-tr from-indigo-700 to-fuchsia-500" style={{WebkitBoxDecorationBreak : 'clone'}}>something great</span> together.</motion.h1>
          <motion.h2 initial={{ opacity: 0, y: 15 }} whileInView={{ opacity: 1, y: 0, transition: { delay: 0.075 } }} viewport={{ once: true }} className="mx-auto text-xl md:text-2xl lg:text-3xl font-regular leading-tight md:leading-tight lg:leading-tight mt-2 md:mt-3 lg:mt-4 xl:mt-5 text-zinc-600 tracking-tight">Get in touch, no matter how big or small your project is</motion.h2>
          <motion.p initial={{ opacity: 0, y: 15 }} whileInView={{ opacity: 1, y: 0, transition: { delay: 0.1 } }} viewport={{ once: true }} className="mx-auto text-sm md:text-base font-regular leading-tight md:leading-tight lg:leading-tight mt-6 md:mt-8 text-zinc-600 px-5 py-2.5 bg-zinc-100 rounded-full inline-block">Not a fan of forms? Send me an email directly: <a href="mailto:contact@donatas.online" className="text-zinc-700 underline hover:text-zinc-900">contact@donatas.online</a></motion.p>
        </div>
      </div>
    </div>
    <motion.div initial={{ opacity: 0, y: 30 }} whileInView={{ opacity: 1, y: 0, transition: { delay: 0.125 } }} viewport={{ once: true }} className="container mx-auto px-4 max-w-2xl relative z-20 pt-16 md:pt-24" id="message">

    <motion.div layout style={{ opacity: (showSuccess ? '1' : '0'), height: (showSuccess ? 'auto' : '0px') }} className="overflow-hidden">
      <div className="p-4 md:p-6 bg-green-600 text-white text-lg md:text-xl rounded-xl mb-8">
        Your message has been sent successfully! I will review it and reply as soon as possible.
      </div>
    </motion.div>
    <motion.div layout style={{ opacity: (showError ? '1' : '0'), height: (showError ? 'auto' : '0px') }} className="overflow-hidden">
      <div className="p-4 md:p-6 bg-red-600 text-white text-lg md:text-xl rounded-xl mb-8">
        Oops, something went wrong.. Please try again, or email me directly: <a href="mailto:contact@donatas.online" className="underline text-white">contact@donatas.online</a>
      </div>
    </motion.div>
      <form onSubmit={handleOnSubmit} className="space-y-5 md:space-y-7">
              <div>
                <label htmlFor="name" className="block text-lg font-medium text-zinc-900">
                  Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="block w-full rounded-lg border-zinc-300 shadow-sm focus:border-zinc-900 focus:ring-zinc-900 md:text-lg py-3 px-4"
                    placeholder="John Doe"
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="email" className="block text-lg font-medium text-zinc-900">
                  Email Address
                </label>
                <div className="mt-2">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="block w-full rounded-lg border-zinc-300 shadow-sm focus:border-zinc-900 focus:ring-zinc-900 md:text-lg py-3 px-4"
                    placeholder="email@email.com"
                    required
                  />
                </div>
              </div>
              <div className="pb-2">
      <label className="block text-lg font-medium text-zinc-900">Estimated budget</label>
      <p className="text-sm md:text-base leading-5 text-zinc-500">Rough estimate of your project's budget</p>
      <fieldset className="mt-4">
        <legend className="sr-only">Budget</legend>
        <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
          {budget.map((budgetItem) => (
            <div key={budgetItem.id} className="flex items-start">
              <input
                id={budgetItem.id}
                name="budget"
                value={budgetItem.title}
                type="radio"
                className="h-5 w-5 mt-0.5 md:mt-1.5 border-zinc-400 text-zinc-900 focus:ring-zinc-900"
                required
              />
              <label htmlFor={budgetItem.id} className="ml-3 block leading-tight md:text-lg md:leading-tight font-medium text-zinc-700">
                {budgetItem.title}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
              <div>
                <label htmlFor="summary" className="block text-lg font-medium text-zinc-900">
                  Short summary of your project & what services you require
                </label>
                <div className="mt-2">
                  <textarea
                    rows={5}
                    name="summary"
                    id="summary"
                    className="block w-full rounded-lg border-zinc-300 shadow-sm focus:border-zinc-900 focus:ring-zinc-900 md:text-lg py-3 px-4"
                    defaultValue={''}
                    required
                  />
                </div>
              </div>
              
              <button style={{ opacity: (serverState.submitting ? '0.5' : '1') }} disabled={serverState.submitting} type="submit" className="appearance-none mx-auto text-white font-medium text-lg bg-zinc-900 rounded-full px-6 py-3 flex items-center space-x-3 hover:bg-black transition-all group">
                    <span className="relative z-10">Submit form</span>
                        <svg className="fill-current w-5 h-5 transform transition-transform group-hover:translate-x-1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            width="30" height="30"
            viewBox="0 0 30 30"><path d="M 22.990234 8.9902344 A 1.0001 1.0001 0 0 0 22.292969 10.707031 L 25.585938 14 L 2 14 A 1.0001 1.0001 0 1 0 2 16 L 25.585938 16 L 22.292969 19.292969 A 1.0001 1.0001 0 1 0 23.707031 20.707031 L 28.619141 15.794922 A 1.0001 1.0001 0 0 0 28.623047 14.207031 A 1.0001 1.0001 0 0 0 28.617188 14.203125 L 23.707031 9.2929688 A 1.0001 1.0001 0 0 0 22.990234 8.9902344 z"></path></svg>
                  
                  </button>
          </form>
    </motion.div>
  </Layout>
);
  };
  

export default IndexPage
